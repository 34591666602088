<!--交易平台-->
<template>
  <div class="box">
    <div class="nav_top">
      <div class="top"><top-nav></top-nav></div>
      <!-- <h1>环保装备交易平台</h1>
      <h2>服务环境产业上下游</h2> -->
      <div class="text">
        <div class="first">绿水青山就是金山银山</div>
      </div>
      <div class="bom">
        <div
          v-for="(item, index) in navBomList"
          :key="index"
          class="list_style"
          :class="index == navBomList.length - 1 ? '' : 'a_style'"
        >
          <div class="title">{{ item.title }}</div>
          <div class="title2">{{ obj[item.num] }}</div>
        </div>
      </div>
    </div>
    <div class="briefIntr">
      <div class="left">
        <div class="title">交易平台简介</div>
        <div class="title2">PLATFORM INTRODUCTION</div>
        <div class="line"></div>
        <div class="text">
          环保装备及材料交易平台立足于义乌，以长三角为重点服务区域，辐射全国，并
          充分利用义乌的营商环境和对外交流渠道，配合国家“一带一路”大政方针积极
          走出去。环保装备平台以科技创新助力产业转型升级，为义乌市营商环境打造地方经济的转型升级服务。
        </div>

        <div class="old">
          <a target="_blank" href="http://testoffice.rcees-csj.com/officePc/#/"
            >环保装备交易中心>></a
          >
        </div>
      </div>
      <div class="right">
        <img src="../assets/4.png" width="100%" height="100%" />
      </div>
    </div>
    <div class="banner">
      <div class="title">供应商TOP10</div>
      <div class="title2">SUPPLIER RANKING</div>
      <div class="line"></div>
      <div class="title3">平台根据成交的总金额选出十名最优秀的供应商</div>
      <div class="bom">
        <img src="../assets/transaction/bom.png" />
      </div>
    </div>
    <div class="suppliers">
      <div
        class="list_style"
        v-for="(item, index) in supplierList"
        :key="index"
        @click="goDetails(item)"
      >
        <div class="left">
          <div
            class="imgs"
            :style="{
              backgroundImage: 'url(' + item.logoUrl + ')',
              backgroundSize: '100% 100%',
            }"
          ></div>
          <div class="subscript" v-if="index < 3">
            <div class="top">TOP{{ index + 1 }}</div>

            <img src="../assets/transaction/1.png" width="100%" height="100%" />
          </div>
          <div class="subscript" v-else>
            <div class="top">TOP{{ index + 1 }}</div>
            <img src="../assets/transaction/2.png" width="100%" height="100%" />
          </div>
        </div>
        <div class="right">
          <div class="title">{{ item.username }}</div>
          <div class="text">
            {{ item.synopsis }}
          </div>
          <div class="right_bom">
            <div>
              成交订单总金额：<span class="money">{{ item.amountSum }}</span
              >元
            </div>
            <div>成交订单数量：{{ item.orderNum }}件</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content5">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import topNav from "../components/topNav";
import bottom from "../components/bottom";
import { leaderboard, statistics } from "../api/api";

export default {
  components: {
    topNav,
    bottom,
  },
  data() {
    return {
      userInfo: "",
      obj: {
        supplierNum: "",
        userNum: "",
        orderNum: "",
        amountSum: "",
      },
      navBomList: [
        {
          title: "注册供应商数量",
          num: "supplierNum",
        },
        {
          title: "注册用户数量",
          num: "userNum",
        },
        {
          title: "成交订单数量",
          num: "orderNum",
        },
        {
          title: "成交订单总金额(元）",
          num: "amountSum",
        },
      ],
      supplierList: [
        {
          title: "供应商名称",
          briefIntr:
            "供应商简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介",
          money: 35900,
          num: 3500,
        },
        {
          title: "供应商名称",
          briefIntr:
            "供应商简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介",
          money: 35900,
          num: 3500,
        },
        {
          title: "供应商名称",
          briefIntr:
            "供应商简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介",
          money: 35900,
          num: 3500,
        },
        {
          title: "供应商名称",
          briefIntr:
            "供应商简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介",
          money: 35900,
          num: 3500,
        },
        {
          title: "供应商名称",
          briefIntr:
            "供应商简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介",
          money: 35900,
          num: 3500,
        },
        {
          title: "供应商名称",
          briefIntr:
            "供应商简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介",
          money: 35900,
          num: 3500,
        },
        {
          title: "供应商名称",
          briefIntr:
            "供应商简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介",
          money: 35900,
          num: 3500,
        },
        {
          title: "供应商名称",
          briefIntr:
            "供应商简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介简介",
          money: 35900,
          num: 3500,
        },
      ],
    };
  },
  mounted() {
    this.getinto();
  },
  methods: {
    async getinto() {
      let res = await statistics();
      if (res.ResultCode == 0) {
        this.obj = res.Data;
        // console.log(res,'res')
      }
      let newres = await leaderboard();
      if (newres.ResultCode == 0) {
        // this.obj=newres.Data
        this.supplierList = newres.Data;
        // console.log(newres.Data, "new交易平台res");
      }
    },
    goDetails(e) {
      // console.log(e,'eee');
      this.$router.push({
        path: "/trandingDetail",
        query: {
          item: e,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  min-width: 1000px;
  box-sizing: border-box;
  .nav_top {
    width: 100%;
    height: 540px;
    background-image: url("../assets/banner_mao.png");
    background-repeat: no-repeat;
    background-size: 100% 540px;
    box-sizing: border-box;
    position: relative;
    .text {
      height: calc(100% - 200px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
      text-align: center;
      font-size: 3.125rem;
      font-family: 'YanShiYouRanXiaoKai';

      transition: bounce-in 3s;

      .first {
        font-size: 6.875rem;
        // font-weight: bold;
        margin-bottom: 3.125rem;
      }
    }
    .top {
      width: 100%;
      height: 100px;
      background-size: 100% 100px;
      background-repeat: no-repeat;
    }
    h1 {
      font-size: 66px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 170px;
    }
    h2 {
      font-size: 40px;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.8);
      text-align: center;
      margin-top: 51px;
    }
    .bom {
      // margin: 0 250px;
      // margin-top: 317px;
      display: flex;
      flex: 1;
      justify-content: space-between;
      width: 81.25rem;
      position: absolute;
      bottom: 1.625rem;
      left: 50%;
      transform: translateX(-50%);

      .list_style {
        padding-right: 8.5625rem;
        cursor: pointer;
        .title {
          font-size: 1.125rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: rgba($color: #ffffff, $alpha: 0.8);
        }
        .title2 {
          font-size: 2.125rem;
          font-family: DIN Alternate;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          margin-top: 1.5625rem;
        }
      }

    }
  }
  .briefIntr {
    width: 87.5rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 9.9375rem 0 11.0625rem 0;
    .left {
      .old {
        margin-top: 4.25rem;
        width: 14.5rem;
        height: 2.875rem;
        text-align: center;
        font-weight: bold;
        line-height: 2.875rem;
        border: 2px dashed #2477e4;
        a {
          padding: 1.25rem 2.5rem;
          color: #076ce0;
        }
      }
      .title {
        font-size: 2.25rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .title2 {
        font-size: 1.5rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin-top: 1.0625rem;
      }
      .line {
        width: 1.875rem;
        height: .3125rem;
        background: #076ce0;
        margin: 1.9375rem 0 2.1875rem 0;
      }
      .text {
        width: 42.0625rem;
        font-size: 1rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #595959;
      }
      .old:hover {
      transform: scale(1.1);
      transition: all .8s ;
      }

    }
    .right {
      // flex: 1;
      width: 26.125rem;
      height: 16.875rem;
      background-color: #333333;
    }
    .right:hover {
      transform: scale(1.2);
      transition: all .8s ease-in;
    }
  }
  .banner {
    width: 100%;
    height: 28.375rem;
    background-image: url("../assets/transaction/bg.png");
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    padding-top: .0625rem;
    position: relative;
    .title {
      font-size: 2.25rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #efefef;
      text-align: center;
      margin-top: 7.8125rem;
    }
    .title2 {
      font-size: 1.5rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #efefef;
      opacity: 0.7;
      text-align: center;
    }
    .line {
      width: 1.875rem;
      height: .3125rem;
      background: #ffffff;
      margin: 0 auto;
      margin-top: 1.75rem;
      margin-bottom: 4.75rem;
    }
    .title3 {
      font-size: 1.5rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #efefef;
      text-align: center;
    }
    .bom {
      width: 6.625rem;
      height: 2.825rem;
      position: absolute;
      bottom: -0.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .suppliers {
    width: 87.5rem;
    margin: 0 auto;
    padding-top: 143px;
    padding-bottom: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .list_style {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 47%;
      background: #ecf4ff;
      border: 1px solid #1d59e7;
      border-radius: 6px;
      margin-top: 73px;
      .left {
        flex: 0.4;
        .imgs {
          width: 100px;
          height: 100px;
          border-radius: 50px;
          background-repeat: no-repeat;
          //   background: red;
          margin: 0 auto;
          // img{
          //   width: 100%;
          //   height: 100%;
          // border-radius: 50%;
          // }
        }
        .subscript {
          width: 64px;
          height: 24px;
          margin: 0 auto;
          margin-top: -20px;
          position: relative;
          .top {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #fff;
            position: absolute;
            top: 1px;
            left: 17px;
          }
        }
      }
      .right {
        flex: 1.6;
        padding: 30px 26px;
        box-sizing: border-box;
        .title {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #356ceb;
        }
        .text {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 32px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .right_bom {
          display: flex;
          justify-content: space-between;
          div {
            &:first-child {
              .money {
                color: #ff6000;
              }
            }
          }
        }
      }
    }
    .list_style:hover {
      transform: scale(1.1);
      transition: all 1s;
      background: #1d59e7;
      border: 1px solid #ecf4ff;
      .right{
          color: #fff;
        .title{
          color: #fff;
        }
        .text{
          color: rgba(255, 255, 255, 0.8);

        }
        .right_bom {
          div {
            &:first-child {
              .money {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
  .content5 {
    width: 100%;
    height: 310px;
    background: #f5f5f5;
  }
}
.a_style {
  border-right: 1px solid rgba($color: #ffffff, $alpha: 0.4) !important;
}
</style>
